<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Admin Categories</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div v-if="isAdmin === true" class="app-max-width">

        <!-- Buttons -->
        <ion-segment color="secondary" :value="view">
          <ion-segment-button value="list" @click.prevent="view = 'list'">
            <ion-icon :icon="reorderThree"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="add" @click.prevent="view = 'add'">
            <ion-icon :icon="add"></ion-icon>
          </ion-segment-button>
        </ion-segment>

        <!-- List View -->
        <div v-if="view == 'list'">
          <AdminCategoryList />
        </div>
        <!-- Add View -->
        <div v-if="view == 'add'">
          <CategoryForm />
        </div>

      </div>
      <div v-else class="app-max-width">
        Not authorized
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonSegment, IonSegmentButton } from '@ionic/vue';
import { chevronBack, add, reorderThree } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
import AdminCategoryList from '../../components/AdminCategoryList.vue';
import CategoryForm from '../../components/CategoryForm.vue';

export default defineComponent({
  name: 'AdminCategories',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonSegment, IonSegmentButton, AdminCategoryList, CategoryForm
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const isAdmin = ref(true);
    const view = ref('list');

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, router, updateRoute, goBack, isAdmin, reorderThree, view
    }
  }
});
</script>

<style scoped>

</style>