<template>
	<div>
		<!-- Categories -->
		<div v-if="categories.length > 0" class="padding-top-md">
			<ion-list>
				<ion-item v-for="category in categories" :key="category" button detail :href="'/admin-category/'+category.id">
					<div tabindex="0"></div>
					{{category.name}}
				</ion-item>
			</ion-list>
		</div>
		<!-- None -->
		<div v-else>
			<ion-item>
				<div tabindex="0"></div>
				There are no categories
			</ion-item>
		</div>
	</div>
</template>

<script>
import { IonList, IonItem } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
//import { image, trash } from 'ionicons/icons';
import apiClient from '../services/api';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'AdminCategoryList',
	props: [],
	components: {
		IonList, IonItem
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const categories = ref([]);

		onMounted(() => {
			getCategories()
		})

		function getCategories() {
			//presentLoading()
			apiClient.get('/api/get/categories', {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				categories.value = response.data
			}).catch(error => {
				console.log(error)  
			});      
		}

		function editCategory(id) {
			router.push('/admin-category/'+id)
		}

		return {
			authUser, categories, editCategory, router
		}
	},
});
</script>